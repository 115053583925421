import React from "react"
import ReactHtmlParser from 'react-html-parser'

import { graphql, Link } from "gatsby"
import clip from "text-clipper"

import Layout from "../parts/layout"
import Seo from "../parts/seo"

const UseCasesPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Use Cases" />
      <div className="row">
        <h1 className="page-title">Use Cases</h1>
        <div className="items">
          { data.allStrapiUseCase.nodes.map(node => {
            const excerpt = node.excerpt ? node.excerpt : clip(node.description, 150, { html: true })

            return (
              <div key={node.id} className="item">
                <h2><Link to={`/use-cases/${node.slug}`}>{ node.title }</Link></h2>
                { ReactHtmlParser(excerpt) }
                <div className="buttons">
                  <Link className="button" to={`/use-cases/${node.slug}`}>Read more...</Link>
                </div>
              </div>
            )
          }) }
        </div>
      </div>
    </Layout>
  )
}

export default UseCasesPage

export const pageQuery = graphql`
  query UseCasesQuery {
    allStrapiUseCase {
      nodes {
        id
        slug
        title
        excerpt
        description
      }
    }
  }
`